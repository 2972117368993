import '../src/styles/globals.scss';
import {Route, Routes, useLocation} from 'react-router-dom';
import {LoginPage} from "./root/login/modules/pages/LoginPage";
import {PasswordForgottenPage} from "./root/login/modules/pages/PasswordForgottenPage";
import {PasswordResetPage} from "./root/login/modules/pages/PasswordResetPage";
import {AssortmentPage} from "./root/product/modules/pages/assortmentPage";
import {ProductPage} from "./root/product/modules/pages/productPage";
import {ProtectedRoute} from "./shared/Route/features/ProtectedRoute";
import {
  checkLoggedIn,
  getCurrentUser,
  resetCurrentUserStorage
} from "./root/login/infrastructure/slices/currentUserSlice/currentUserSlice";
import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "./hooks/useTypedSelector";
import {PublicRoute} from "./shared/Route/features/PublicRoute";
import axios from "axios";
import {CartPage} from "./root/cart/modules/pages/cartPage";
import {CheckoutPage} from "./root/checkout/modules/pages/checkoutPage";
import {CheckoutCompletedPage} from "./root/checkout/modules/pages/checkoutCompletedPage";
import {Loading} from "./root/general/features/loading";
import { ContentPage } from './root/content/modules/pages/ContentPage';
import {CheckoutUpdatePage} from "./root/checkout/modules/pages/checkoutUpdatePage";
import Messages from "./root/general/Messages/messages";
import {OrderDetailPage} from "./root/account/modules/pages/orderDetailPage";
import {OrdersPage} from "./root/account/modules/pages/ordersPage";
import {AccountPage} from "./root/account/modules/pages/accountPage";
import i18next from "i18next";
import {supportedLanguages} from "./root/header/modules/features/langSelect";
import {CashInvoicePaymentUpdatePage} from "./root/invoice/modules/pages/CashInvoicePaymentUpdatePage";
import {CashInvoicePaymentCompletedPage} from "./root/invoice/modules/pages/CashInvoicePaymentCompletedPage";
import {CashInvoicePaymentFailedPage} from "./root/invoice/modules/pages/CashInvoicePaymentFailedPage";

const App = () => {
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if ('response' in error && 'status' in error.response && error.response.status === 403) {
            resetCurrentUserStorage();
            window.location.href = "/user/login";
        }
        return Promise.reject(error);
    });

    const dispatch = useAppDispatch();
    const {currentUser, loading} = useAppSelector(state => state.currentUser);

    useEffect(() => {
        if (currentUser === undefined) {
            dispatch(getCurrentUser());
        }
        else {
          dispatch(checkLoggedIn());
        }
    }, []);

    const location = useLocation();

    useEffect(() => {
        const language = location.pathname.split('/')[1];
        if (language && supportedLanguages.map(lang => lang.langcode).includes(language)) {
            i18next.changeLanguage(language); // Change language
        }
    }, [location, i18next]);

    if (loading) {
        return <Loading/>;
    }

    return (
        <>
            <Messages />
            <Routes>
                <Route index path="/:lang?/" element={
                    <PublicRoute user={currentUser}><LoginPage/></PublicRoute>
                }/>
                <Route path="/:lang?/user/login" element={
                    <PublicRoute user={currentUser}><LoginPage/></PublicRoute>
                }/>
                <Route path="/:lang?/user/password" element={
                    <PublicRoute user={currentUser}><PasswordForgottenPage/></PublicRoute>
                }/>
                <Route path="/:lang?/user/password/reset/:user/:timestamp?/:hash?" element={
                    <PublicRoute user={currentUser}><PasswordResetPage/></PublicRoute>
                }/>

                <Route path={'/:lang'}>
                    <Route path="assortment" element={
                        <ProtectedRoute user={currentUser}><AssortmentPage/></ProtectedRoute>
                    }/>
                    <Route path="assortment/order-list" element={
                        <ProtectedRoute user={currentUser}><AssortmentPage/></ProtectedRoute>
                    }/>
                    <Route path="assortment/:id" element={
                        <ProtectedRoute user={currentUser}><ProductPage/></ProtectedRoute>
                    }/>
                    <Route path="cart/" element={
                        <ProtectedRoute user={currentUser}><CartPage/></ProtectedRoute>
                    }/>
                    <Route path="cart/checkout" element={
                        <ProtectedRoute user={currentUser}><CheckoutPage/></ProtectedRoute>
                    }/>
                    <Route path="cart/checkout/:order/update" element={
                        <ProtectedRoute user={currentUser}><CheckoutUpdatePage /></ProtectedRoute>
                    }/>
                    <Route path="cart/checkout/:order/completed" element={
                        <ProtectedRoute user={currentUser}><CheckoutCompletedPage/></ProtectedRoute>
                    }/>
                    <Route path="account/orders/:id" element={
                        <ProtectedRoute user={currentUser}><OrderDetailPage/></ProtectedRoute>
                    }/>
                    <Route path="account/orders" element={
                        <ProtectedRoute user={currentUser}><OrdersPage/></ProtectedRoute>
                    }/>
                    <Route path="account" element={
                        <ProtectedRoute user={currentUser}><AccountPage/></ProtectedRoute>
                    }/>
                    <Route path="cash-invoices/payment/:cashInvoice/update" element={
                        <ProtectedRoute user={currentUser}><CashInvoicePaymentUpdatePage/></ProtectedRoute>
                    }/>
                    <Route path="cash-invoices/payment/:cashInvoice/completed" element={
                        <ProtectedRoute user={currentUser}><CashInvoicePaymentCompletedPage/></ProtectedRoute>
                    }/>
                    <Route path="cash-invoices/payment/:cashInvoice/failed" element={
                        <ProtectedRoute user={currentUser}><CashInvoicePaymentFailedPage/></ProtectedRoute>
                    }/>
                    <Route path="*" element={
                        <ProtectedRoute user={currentUser}><ContentPage/></ProtectedRoute>
                    }/>
                </Route>

                <Route path="*" element={
                    <ProtectedRoute user={currentUser}><ContentPage/></ProtectedRoute>
                }/>
            </Routes>
        </>
    );
};

export default App;
