import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios, {AxiosError} from "axios";
import {CornyBakersEnv} from "../../../../../shared/environment/features/CornyBakersEnvironment";
import {Category, MenuSliceModel} from "./menuSliceModel";
import i18next from "i18next";

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

interface CategoryResponseItem {
    id: string;
    attributes: {
        name: string;
        field_title: string;
        weight: number;
        drupal_internal__tid: number;
    },
    relationships: {
        parent: {
            data: { id: string }[]
        }
    }
}

export const getCategories = createAsyncThunk(
    "menuSlice/getCategories",
    async (_, thunkAPI) => {
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;

        const url = `${API_BASE_URL}${formatLang}/jsonapi/index/products?filter[entity_type]=taxonomy_term`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const productsResponse = response.data;

            if (productsResponse.data.length === 0) {
                return thunkAPI.rejectWithValue('No products found');
            }

            // Parse categories from response
            const categories = response.data.data.map((item: CategoryResponseItem) => ({
                id: item.id,
                name: item.attributes.field_title ?? item.attributes.name,
                weight: item.attributes.weight,
                drupal_id: item.attributes.drupal_internal__tid,
                parent: (item.relationships.parent.data.length > 0 && item.relationships.parent.data[0].id !== 'virtual' ? item.relationships.parent.data[0].id : null),
            }));

            // Build nested structure
            const categoryMap = new Map<string, Category>();
            const rootCategories: Category[] = [];

            categories.forEach((category: Category) => {
                categoryMap.set(category.id, {
                    ...category,
                    children: []
                });
            });

            categoryMap.forEach((category) => {
                if (category.parent) {
                    const parentCategory = categoryMap.get(category.parent);
                    if (parentCategory) {
                        parentCategory.children.push(category);
                    }
                } else {
                    rootCategories.push(category);
                }
            });

            // Sort categories and their children, also map parent IDs
            const sortCategories = (categories: Category[], parentIds: number[] = []): Category[] => {
                return categories
                    .sort((a, b) => a.weight - b.weight)
                    .map((category) => {
                        // Recursively sort children and collect their IDs
                        const sortedChildren = sortCategories(category.children, [...parentIds, category.drupal_id]);
                        const childDrupalIds = sortedChildren.map((child) => child.drupal_id);

                        return {
                            ...category,
                            children: sortedChildren,
                            childDrupalIds,
                            parentDrupalIds: parentIds,  // Add the collected parent IDs to the category
                        };
                    });
            };

            return sortCategories(rootCategories);

        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    });

const initialState = {
    loading: false,
    loaded: false,
    categories: [] as Category[],
    mainCategories: [] as Category[],
} as MenuSliceModel;

const menuSlice = createSlice({
    name: "menuSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCategories.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCategories.rejected, (state) => {
                state.loading = false;
                state.loaded = true;
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.loaded = true;
                state.categories = action.payload as Category[];
                state.mainCategories = (action.payload as Category[])
                    .filter((item: Category) => (item?.parent ?? null) === null)
                    .sort((itemA: Category, itemB: Category) => itemA.weight > itemB.weight ? 1 : -1);
            })
    }
});

export default menuSlice.reducer;
