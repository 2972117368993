import {Accordion, Card, Spinner} from "react-bootstrap";
import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useTypedSelector";
import {Category} from "../../../../header/infrastructure/slices/menuSlice/menuSliceModel";
import CategoryService from "../../../../header/infrastructure/services/categoryService";
import {ProductMenuItem} from "../../features/menu/ProductMenu";
import {getCategories} from "../../../../header/infrastructure/slices/menuSlice/menuSlice";

const ProductMenu = () => {
    const {t} = useTranslation('common');
    const {loading, categories} = useAppSelector(state => state.menu);
    const dispatch = useAppDispatch();

    const categoryService = useMemo(() => new CategoryService(categories), [categories.length]);

    useEffect(() => {
        if (!loading) {
            dispatch(getCategories());
        }
    }, []);

    return <Card bg={'primary'} className={'product-categories'}>
        <Card.Body>
            <h2 className={'mt-2'}>{t('Product categories')}</h2>

            {loading && <Spinner/>}

            <Accordion className={'product-menu'}>
                {categories.map((category: Category, delta: number) => (
                    <ProductMenuItem key={delta} category={category} service={categoryService} />
                ))}
            </Accordion>
        </Card.Body>
    </Card>
}

export default ProductMenu;