import {useTranslation} from "react-i18next";
import {BaseAccountPage} from "../Features/baseAccountPage";
import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {
    getOrders,
    itemsPerPage,
    setPage
} from "../../infrastructure/slices/ordersSlice";
import {Loading} from "../../../general/features/loading";
import {Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import Pagination from "../../../product/modules/pages/productRange/Pagination";
import {OrderColView} from "../Features/OrderColView";
import {getOpenInvoices} from "../../../invoice/infrastructure/slice/invoicesSlice";
import {InvoiceWithoutOrderColView} from "../Features/InvoiceWithoutOrderColView";

export function OrdersPage() {
    const {t} = useTranslation('common');
    const dispatch = useAppDispatch();
    const {loading, orders,page, totalItems} = useAppSelector(state => state.orders)
    const {loading: invoicesLoading, invoicesWithoutOrder, invoicesWithOrder} = useAppSelector(state => state.invoices)

    useEffect(() => {
        if (!loading) {
            dispatch(getOrders());
        }
    }, [page]);

    useEffect(() => {
        if (!invoicesLoading) {
            dispatch(getOpenInvoices());
        }
    }, []);

    const navigation = useNavigate();

    const changePage = (newPage: number) => {
        const url = new URL(window.location.href);
        url.searchParams.set('page', newPage.toString());

        navigation(`${url.pathname}${url.search}`);
        dispatch(setPage(newPage));
    }

    return (
        <BaseAccountPage pageTitle={t('Orders')}>
            <Row>
                <Col xs={12} className={'mb-4'}>
                    <h3>{t('My orders')}</h3>
                </Col>
                <div className={'p-rel mh-200'}>
                    {invoicesLoading ? <Loading /> : (
                        <>
                            {invoicesWithoutOrder.length > 0 && (
                                <>
                                    <h6>{t('Orders outside the webshop')}</h6>
                                    {invoicesWithoutOrder.map((invoice) => <InvoiceWithoutOrderColView key={invoice.id} invoice={invoice} />)}
                                </>
                            )}
                        </>
                    )}
                    {loading ? <Loading /> : (
                        <>
                            <h6>{t('Webshop orders')}</h6>
                            {orders.map((order) => (
                                <OrderColView key={order.id} order={order}
                                              invoice={invoicesWithOrder.find((item) => item.orderId == `${order.attributes.drupal_internal__order_id}`)} />
                            ))}
                        </>
                    )}
                </div>
                <Col xs={12}>
                    <Pagination page={page} maxPage={Math.ceil(totalItems / itemsPerPage)}
                                setPage={changePage}/>
                </Col>
            </Row>

        </BaseAccountPage>
    );
}

