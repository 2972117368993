import {useTranslation} from "react-i18next";
import React from "react";
import {BiChevronRight} from "react-icons/bi";
import {useNavigate, useParams} from "react-router-dom";
import {OrderModel} from "../../domain/models/orderSliceModel";
import {Col} from "react-bootstrap";
import {formatOrderDate} from "../../infrastructure/utility/utility";
import {InvoiceModel} from "../../../invoice/domain/invoiceSliceModel";
import {formatPriceToEuro} from "../../../../shared/utility/converters";
import {useInvoicePaymentHandlers} from "../../../invoice/infrastructure/hooks/useInvoicePaymentHandlers";

type Props = {
    order: OrderModel;
    invoice: InvoiceModel|null|undefined;
};

export function OrderColView({order, invoice}: Props) {
    const {t} = useTranslation('common');
    const {lang} = useParams();
    const {createPayment} = useInvoicePaymentHandlers();

    const navigation = useNavigate();
    const handleClick = (event: {preventDefault(): void }, id: string) => {
        event.preventDefault();

        navigation(`/${lang}/account/orders/${id}`);
    }

    return (
        <Col xs={12} className={'order-col'}>
            <div className={"row"}>
                <div className={"col-12 text-end d-flex justify-content-end align-items-center"}>
                    <div className={"me-auto"}>
                        {order.attributes.placed ? formatOrderDate(order.attributes.placed) : ''}
                        {order.attributes.placed ? <>&nbsp; | &nbsp;</> : ''}
                        {t('Order number')} {order.attributes.order_number}
                    </div>
                    <div className={"me-4"}>
                        <b>{t('Total (incl. vat)')} {order.attributes.total_price.formatted}</b>
                    </div>
                    <a className={'rounded-circle order-button'} href={`/${lang}/account/orders/${order.id}`}
                       onClick={event => handleClick(event, order.id)}>
                        <BiChevronRight size={'20px'} color={'white'} />
                    </a>
                </div>
                {invoice && (
                    <div className={"col-12"}>
                        {t('Invoice number')} {invoice.invoiceNumber} &nbsp; | &nbsp;

                        {t('Outstanding (incl. VAT)')}: {formatPriceToEuro(invoice.outstandingAmount)}

                        <a className={'btn btn-primary btn-sm ms-4'} onClick={() => createPayment(invoice.id)}>
                            {t('Pay now')}
                            <BiChevronRight size={'20px'} color={'white'} />
                        </a>
                    </div>
                )}
            </div>
        </Col>
    );
}

