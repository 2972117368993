import {PageTitle} from "../../../page/features/PageTitle";
import {useTranslation} from "react-i18next";
import {DefaultPage} from "../../../page/features/default";
import {Col, Row} from "react-bootstrap";
import React from "react";
import {useSearchParams} from "react-router-dom";

export function CashInvoicePaymentCompletedPage() {
  const {t} = useTranslation('common');
    const [searchParams] = useSearchParams();

  return (
      <DefaultPage className={"mt-5 checkout-completed"} showBreadCrumbs={false}>
          <PageTitle title={t('Invoice paid')}/>
          <Row>
              <Col xs={12} className={'mb-5'}>
                  <div className={'checkout-completed-notification'}>
                      <p>
                          {t('You have paid invoice {{invoiceNumber}} successfully.', {
                              invoiceNumber: searchParams.get('invoiceNumber')
                          })}
                      </p>
                  </div>
              </Col>
          </Row>
      </DefaultPage>
  )
}