import {PageTitle} from "../../../page/features/PageTitle";
import {useTranslation} from "react-i18next";
import {DefaultPage} from "../../../page/features/default";
import {Col, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import {Loading} from "../../../general/features/loading";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import {refreshInvoicePaymentUpdate} from "../../infrastructure/slice/invoicesSlice";

export function CashInvoicePaymentUpdatePage() {
    const {t} = useTranslation('common');
    const {cashInvoice, lang} = useParams();
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const {error, invoicePaymentUpdate} = useAppSelector(state => state.invoices);

    useEffect(() => {
        if (cashInvoice && (!invoicePaymentUpdate || (invoicePaymentUpdate.waiting ?? false))) {
            const reload = () => {
                dispatch(refreshInvoicePaymentUpdate({cashInvoiceId: cashInvoice}))
            }

            const id = setTimeout(reload, 1500);

            return () => {
                clearTimeout(id);
            }
        }
    }, [invoicePaymentUpdate]);

    useEffect(() => {
        if (invoicePaymentUpdate && invoicePaymentUpdate.status === 'success') {
            navigation(`/${lang}/cash-invoices/payment/${cashInvoice}/completed?invoiceNumber=${invoicePaymentUpdate.invoiceDetails.invoiceNumber}`);
        }
        else if (invoicePaymentUpdate && invoicePaymentUpdate.status === 'error' && !(invoicePaymentUpdate.waiting ?? false)) {
            navigation(`/${lang}/cash-invoices/payment/${cashInvoice}/failed?invoiceNumber=${invoicePaymentUpdate.invoiceDetails.invoiceNumber}`);
        }
    }, [invoicePaymentUpdate]);

  return (
    <DefaultPage className={"mt-5 checkout-completed"} showBreadCrumbs={false}>
      <PageTitle title={t('State check')}/>
      <Row>
        <Col xs={12} className={'mb-5'}>
            {error && <p className={'text-danger'}>{error}</p>}
            {invoicePaymentUpdate && <p>{invoicePaymentUpdate.message}</p>}

            <div className={'w-100 p-rel'} style={{height: '150px'}}>
                <Loading />
            </div>
        </Col>
      </Row>
    </DefaultPage>
  )
}