import {useAppDispatch} from "../../../../hooks/useTypedSelector";
import {createCashInvoicePayment} from "../slice/invoicesSlice";

export const useInvoicePaymentHandlers = () => {
    const dispatch = useAppDispatch();

    return {
        createPayment: async (cashInvoiceId: string) => {
            const paymentCreateResult = await dispatch(createCashInvoicePayment({cashInvoiceId}));
            const paymentUrl = paymentCreateResult.payload.paymentUrl;
            if (!paymentUrl) {
                console.error('Payment creation failed');
                return;
            }

            window.location.href = paymentUrl as string;
        }
    };
};