import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CartPayload, CartSliceModel} from "./cartSliceModel";
import {ProductModel} from "../../../product/domain/models/productModel";
import {CornyBakersEnv} from "../../../../shared/environment/features/CornyBakersEnvironment";
import axios, {AxiosError} from "axios";
import {AdjustmentInterface, CartApiResponseModel, OrderItemFormatted} from "../../domain/models/cartApiResponseModel";
import {RootState} from "../../../../store";
import i18next from "i18next";

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

const initialState: CartSliceModel = {
    loading: false,
    cartLoading: false,
    cartEmpty: false,
    cartLoaded: false,
    productsLoading: false,
    productsLoaded: false,
    products: [] as ProductModel[],
    cart: {
        items: [] as OrderItemFormatted[],
        totals: {
            total_price: {
                number: '',
                currency_code: 'EUR',
                formatted: ''
            },
            sub_total: {
                number: '',
                currency_code: 'EUR',
                formatted: ''
            },
        },
        adjustments: [] as AdjustmentInterface[],
    },
    order: 0,
    order_uuid: '',
    error: null,
    payment_method: null,
    payment_gateway: null,
    shipments: [],
    req_dlv_date: null
};

export const getCart = createAsyncThunk(
    "cartSlice/getCart",
    async (_, thunkAPI) => {
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;
        const url = API_BASE_URL + formatLang + '/cart?_format=json';

        try {
            const cartResponse = await axios.get<CartApiResponseModel[]>(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (cartResponse.data.length > 0) {
                const cart = cartResponse.data[0].order_items.map((cartItem) => {
                    return {
                        variation: cartItem.purchased_entity.uuid,
                        variation_id: cartItem.purchased_entity.variation_id,
                        product: cartItem.purchased_entity.product_id,
                        order: {id: cartResponse.data[0].order_id, item: cartItem.order_item_id},
                        quantity: parseInt(cartItem.quantity),
                        unit_price: cartItem.unit_price.number,
                        price: cartItem.total_price.formatted,
                        sku: cartItem.purchased_entity.sku,
                        name: cartItem.purchased_entity.title,
                        product_uuid: cartItem.product_uuid,
                        product_images: cartItem.product_images.map(image => image.image_style_uri.thumbnail)
                    };
                }).filter(product => product !== null) as unknown as ProductModel[];

                return {
                    order: cartResponse.data[0].order_id,
                    order__uuid: cartResponse.data[0].uuid,
                    payment_method: cartResponse.data[0].payment_method ?? null,
                    payment_gateway: cartResponse.data[0].payment_gateway ?? null,
                    shipments: cartResponse.data[0].shipments ?? [],
                    req_dlv_date: cartResponse.data[0].req_dlv_date ?? null,
                    cart: {
                        items: cart,
                        totals: {
                            total_price: cartResponse.data[0].total_price,
                            sub_total: cartResponse.data[0].order_total.subtotal,
                        },
                        adjustments: cartResponse.data[0].order_total.adjustments,
                    },
                }
            }
            return [];
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    }
);

export const addToCart = createAsyncThunk(
    "cartSlice/addToCart",
    async (props: { product: ProductModel, quantity: number }, thunkAPI) => {
        const {product, quantity} = props;
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;
        const url = API_BASE_URL + formatLang + '/cart/add?_format=json';

        try {
            await axios.post(url, [{
                purchased_entity_type: 'commerce_product_variation',
                purchased_entity_id: product.variations.drupal_internal__variation_id,
                quantity: quantity,
                combine: true
            }], {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            await thunkAPI.dispatch(getCart());
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    }
);

export const addToCartMultiple = createAsyncThunk(
    "cartSlice/addToCartMultiple",
    async (props: { map: {id: number, quantity: number }[] }, thunkAPI) => {
        const {map} = props;

        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;
        const url = API_BASE_URL + formatLang + '/cart/add?_format=json';

        const data = map.map(item => ({
            purchased_entity_type: 'commerce_product_variation',
            purchased_entity_id: item.id,
            quantity: item.quantity,
            combine: true
        }))

        try {
            await axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            await thunkAPI.dispatch(getCart());
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    }
);

export const patchCartItem = createAsyncThunk(
    "cartSlice/patchCartItem",
    async (props: { itemId: number, cartId: number, quantity: number }, thunkAPI) => {
        const {itemId, quantity, cartId} = props;

        const data = {
            [itemId]: {
                quantity: quantity
            },
        };
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;

        const url = `${API_BASE_URL}${formatLang}/cart/${cartId}/items?_format=json`;
        try {
            await axios.patch(url, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            await thunkAPI.dispatch(getCart());
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    }
);

export const deleteFromCart = createAsyncThunk(
    "cartSlice/deleteFromCart",
    async (props: { product: string }, thunkAPI) => {
        const {product} = props;
        const state = thunkAPI.getState() as RootState;

        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;
        const url = API_BASE_URL + formatLang + '/cart/' + state.cart.order + '/items/' + product + '?_format=json';

        try {
            await axios.delete(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            await thunkAPI.dispatch(getCart());
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    }
);

const cartSlice = createSlice({
    name: 'cartSlice',
    initialState,
    reducers: {
        // Reducers can be added here if needed in the future
    },
    extraReducers: (builder) => {
        builder.addCase(getCart.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCart.rejected, (state) => {
            state.loading = false;
            state.cartLoaded = true;
        });
        builder.addCase(getCart.fulfilled, (state, action) => {
            state.cartEmpty = !('order__uuid' in action.payload);

            state.loading = false;
            const payload = action.payload as unknown as CartPayload; // Type assertion hier
            state.cart = payload.cart;
            state.order = payload.order;
            state.order_uuid = payload.order__uuid;
            state.payment_method = payload.payment_method;
            state.payment_gateway = payload?.payment_gateway?.id ?? null;
            state.shipments = payload.shipments as {
                shipment_id: number,
                shipping_method: number,
                shipping_profile: string|undefined,
                uuid: string
            }[];
            state.req_dlv_date = payload.req_dlv_date;
            state.cartLoaded = true;
        });
        builder.addCase(addToCart.pending, (state) => {
            state.cartLoading = true;
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        builder.addCase(addToCart.rejected, (state, action: PayloadAction<any>) => {
            state.cartLoading = false;
            state.error = action.payload;
        });
        builder.addCase(addToCart.fulfilled, (state) => {
            state.cartLoading = false;
        });
        builder.addCase(addToCartMultiple.pending, (state) => {
            state.cartLoading = true;
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        builder.addCase(addToCartMultiple.rejected, (state, action: PayloadAction<any>) => {
            state.cartLoading = false;
            state.error = action.payload;
        });
        builder.addCase(addToCartMultiple.fulfilled, (state) => {
            state.cartLoading = false;
        });
        builder.addCase(deleteFromCart.pending, (state) => {
            state.cartLoading = true;
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        builder.addCase(deleteFromCart.rejected, (state, action: PayloadAction<any>) => {
            state.cartLoading = false;
            state.error = action.payload;
        });
        builder.addCase(deleteFromCart.fulfilled, (state) => {
            state.cartLoading = false;
        });
        builder.addCase(patchCartItem.pending, (state) => {
            state.cartLoading = true;
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        builder.addCase(patchCartItem.rejected, (state, action: PayloadAction<any>) => {
            state.cartLoading = false;
            state.error = action.payload;
        });
        builder.addCase(patchCartItem.fulfilled, (state) => {
            state.cartLoading = false;
        });
    }
});

export default cartSlice.reducer;
