import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CheckoutSliceModel, PaymentMethod, RelationResponse, ShippingMethod} from "./checkoutSliceModel";
import {CornyBakersEnv} from "../../../../shared/environment/features/CornyBakersEnvironment";
import {RootState} from "../../../../store";
import axios from "axios";
import {getCart} from "../../../cart/infrastructure/slices/cartSlice";
import i18next from "i18next";

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

const initialState: CheckoutSliceModel = {
    loading: false,
    error: null,

    relationError: null,
    relationLoading: false,
    relationLoaded: false,
    relationData: {} as RelationResponse,

    hasCode: false,
    code: '',
    hasComment: false,
    comment: '',
    agreed: false,

    payment_methods_loading: false,
    payment_methods_loaded: false,
    payment_methods: [] as PaymentMethod[],
    payment_methods_error: null,

    shipping_methods_loading: false,
    shipping_methods_loaded: false,
    shipping_methods: [] as ShippingMethod[],
    shipping_methods_error: null,

    delivery_date_loading: false,
    delivery_date_error: null,

    delivery_address_loading: false,
    delivery_address_error: null,
};

export const getPaymentMethods = createAsyncThunk(
    "checkoutSlice/getPaymentMethods",
    async (props: {order: number}, thunkAPI) => {
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;

        const {order} = props;
        const url = `${API_BASE_URL}${formatLang}/api/checkout/${order}/payment`;

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status > 199 && response.status < 300) {
            return response.data;
        }

        return thunkAPI.rejectWithValue('Something went wrong');
    }
)
export const getShippingMethods = createAsyncThunk(
    "checkoutSlice/getShippingMethods",
    async (props: {order: number}, thunkAPI) => {
        const {order} = props;
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;
        const url = `${API_BASE_URL}${formatLang}/api/checkout/${order}/shipping`;

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status > 199 && response.status < 300) {
            return response.data;
        }

        return thunkAPI.rejectWithValue('Something went wrong');
    }
)


export const getRelationInfo = createAsyncThunk(
    "checkoutSlice/getRelationInfo",
    async (_, thunkAPI) => {
        const state: RootState = thunkAPI.getState() as RootState;

        const relation = state?.currentUser?.currentUser?.relation_uuid ?? null;

        if (!relation) {
            return thunkAPI.rejectWithValue('No relation found');
        }

        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;

        try {
            const url = API_BASE_URL + `${formatLang}/jsonapi/relation/relation/${relation}`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status > 199 && response.status < 300) {
                return {
                    id: response.data.data.id,
                    ...response.data.data.attributes,
                };
            }
        }
        catch (e) {
            return thunkAPI.rejectWithValue('Something went wrong');
        }

        return thunkAPI.rejectWithValue('Something went wrong');
    }
)

export const changeShippingMethod = createAsyncThunk(
    "checkoutSlice/changeShippingMethod",
    async (props: {shipping_method: number}, thunkAPI) => {
        const {shipping_method} = props;
        const state: RootState = thunkAPI.getState() as RootState;

        const order = state?.cart?.order ?? null;

        if (!order) {
            return thunkAPI.rejectWithValue('No order found');
        }
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;

        const url = `${API_BASE_URL}${formatLang}/api/checkout/${order}/set-shipping`;
        const response = await axios.post(url, {
            method: shipping_method
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status > 199 && response.status < 300) {
            await thunkAPI.dispatch(getCart());
            return {
                status: 1
            };
        }

        return thunkAPI.rejectWithValue('Something went wrong');
    }
)


export const changePaymentMethod = createAsyncThunk(
    "checkoutSlice/changePaymentMethod",
    async (props: {payment_method: string}, thunkAPI) => {
        const {payment_method} = props;
        const state: RootState = thunkAPI.getState() as RootState;
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;

        const order = state?.cart?.order ?? null;

        if (!order) {
            return thunkAPI.rejectWithValue('No order found');
        }

        const url = `${API_BASE_URL}${formatLang}/api/checkout/${order}/set-payment`;

        const response = await axios.post(url, {
            method: payment_method
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status > 199 && response.status < 300) {
            await thunkAPI.dispatch(getCart());

            return {
                status: 1
            };
        }

        return thunkAPI.rejectWithValue('Something went wrong');
    }
)

export const changeDeliveryDate = createAsyncThunk(
    "checkoutSlice/changeDeliveryDate",
    async (props: {date: string|null}, thunkAPI) => {
        const {date} = props;
        const state: RootState = thunkAPI.getState() as RootState;
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;

        const order = state?.cart?.order ?? null;

        if (!order) {
            return thunkAPI.rejectWithValue('No order found');
        }

        const url = `${API_BASE_URL}${formatLang}/api/checkout/${order}/set-delivery-date`;

        const response = await axios.post(url, {
            date: date
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status > 199 && response.status < 300) {
            await thunkAPI.dispatch(getCart());

            return {
                status: 1
            };
        }

        return thunkAPI.rejectWithValue('Something went wrong');
    }
)

export const changeProfile = createAsyncThunk(
  "checkoutSlice/changeProfile",
  async (props: {profileId: string|null}, thunkAPI) => {
    const {profileId} = props;
    const state: RootState = thunkAPI.getState() as RootState;
    const lang = i18next.language;
    const formatLang = lang === 'nl' ? '' : `/${lang}`;

    const order = state?.cart?.order ?? null;

    if (!order) {
      return thunkAPI.rejectWithValue('No order found');
    }

    const url = `${API_BASE_URL}${formatLang}/api/checkout/${order}/set-profile`;

    const response = await axios.post(url, {
      profileId: profileId
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status > 199 && response.status < 300) {
      await thunkAPI.dispatch(getCart());

      return {
        status: 1,
      };
    }

    return thunkAPI.rejectWithValue('Something went wrong');
  }
)

export const createOrderPayment = createAsyncThunk(
    "checkoutSlice/createOrderPayment",
    async (props: {order: number}, thunkAPI) => {
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;

        const {order} = props;
        const url = `${API_BASE_URL}${formatLang}/api/checkout/${order}/create-payment`;

        const response = await axios.post(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status > 199 && response.status < 300) {
            return response.data;
        }

        return thunkAPI.rejectWithValue('Something went wrong');
    }
)

export const placeOrder = createAsyncThunk(
    "checkoutSlice/placeOrder",
    async (props: {order: number}, thunkAPI) => {
        const {order} = props;
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;
        const url = `${API_BASE_URL}${formatLang}/api/checkout/${order}/place`;

        try {
          const response = await axios.post(url, {
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          if (response.status > 199 && response.status < 300) {

            await thunkAPI.dispatch(getCart());

            return {
              status: 1
            };
          }

          return thunkAPI.rejectWithValue('Something went wrong');
        }
        catch (error) {
          return thunkAPI.rejectWithValue('Something went wrong');
        }


        return thunkAPI.rejectWithValue('Something went wrong');
    }
)

const checkoutSlice = createSlice({
    name: 'checkoutSlice',
    initialState,
    reducers: {
        // Reducers can be added here if needed in the future
        setHasComment: (state, action: PayloadAction<boolean>) => {
            state.hasComment = action.payload;
        },
        setHasCode: (state, action: PayloadAction<boolean>) => {
            state.hasCode = action.payload;
        },
        setCode: (state, action: PayloadAction<string>) => {
            state.code = action.payload;
        },
        setComment: (state, action: PayloadAction<string>) => {
            state.comment = action.payload;
        },
        setAgreed: (state, action: PayloadAction<boolean>) => {
            state.agreed = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRelationInfo.pending, (state) => {
            state.relationLoading = true;
        });
        builder.addCase(getRelationInfo.rejected, (state, action) => {
            state.relationLoaded = true;
            state.relationError = action.payload as string;
        });
        builder.addCase(getRelationInfo.fulfilled, (state, action) => {
            state.relationLoading = false;
            state.relationData = action.payload as RelationResponse;
            state.relationLoaded = true;
        });

        builder.addCase(getShippingMethods.pending, (state) => {
            state.shipping_methods_loading = true;
        });
        builder.addCase(getShippingMethods.rejected, (state, action) => {
            state.shipping_methods_loading = false;
            state.shipping_methods_error = action.payload as string;
        });
        builder.addCase(getShippingMethods.fulfilled, (state, action) => {
            state.shipping_methods_loading = false;
            state.shipping_methods_loaded = true;
            state.shipping_methods = action.payload as ShippingMethod[];
        });

        builder.addCase(getPaymentMethods.pending, (state) => {
            state.payment_methods_loading = true;
        });
        builder.addCase(getPaymentMethods.rejected, (state, action) => {
            state.payment_methods_loading = false;
            state.payment_methods_error = action.payload as string;
        });
        builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
            state.payment_methods_loading = false;
            state.payment_methods_loaded = true;
            state.payment_methods = action.payload as PaymentMethod[];
        });

        builder.addCase(changeShippingMethod.pending, (state) => {
            state.shipping_methods_loading = true;
        });
        builder.addCase(changeShippingMethod.rejected, (state, action) => {
            state.shipping_methods_loading = false;
            state.shipping_methods_error = action.payload as string;
        });
        builder.addCase(changeShippingMethod.fulfilled, (state) => {
            state.shipping_methods_loading = false;
        });

        builder.addCase(changePaymentMethod.pending, (state) => {
            state.payment_methods_loading = true;
        });
        builder.addCase(changePaymentMethod.rejected, (state, action) => {
            state.payment_methods_loading = false;
            state.payment_methods_error = action.payload as string;
        });
        builder.addCase(changePaymentMethod.fulfilled, (state) => {
            state.payment_methods_loading = false;
        });

        builder.addCase(changeDeliveryDate.pending, (state) => {
            state.delivery_date_loading = true;
        });
        builder.addCase(changeDeliveryDate.rejected, (state, action) => {
            state.delivery_date_loading = false;
            state.delivery_date_error = action.payload as string;
        });
        builder.addCase(changeDeliveryDate.fulfilled, (state) => {
            state.delivery_date_loading = false;
        });

        builder.addCase(changeProfile.pending, (state) => {
          state.delivery_address_loading = true;
        });
        builder.addCase(changeProfile.rejected, (state, action) => {
          state.delivery_address_loading = false;
          state.delivery_address_error = action.payload as string;
        });
        builder.addCase(changeProfile.fulfilled, (state) => {
          state.delivery_address_loading = false;
        });
    }
});

export const {setHasComment, setHasCode, setCode, setComment, setAgreed} = checkoutSlice.actions

export default checkoutSlice.reducer;
