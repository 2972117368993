import {Category} from "../slices/menuSlice/menuSliceModel";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import i18next from "i18next";

export default class CategoryService {

    items: Category[] = [] as Category[];

    constructor(items: Category[]) {
        this.items = items;
    }

    applyCategoryFilter = (navigation: NavigateFunction, categoryId: number) => {
       const url = this.softApplyCategoryFilter(categoryId);

        navigation(url);
    }

    softApplyCategoryFilter = (categoryId: number) => {
        const url = new URL(window.location.href);
        if (url.pathname === '/nl/assortment' || url.pathname === '/en/assortment' || url.pathname === '/nl/assortment/order-list' || url.pathname === '/en/assortment/order-list') {
            url.search = '';
            url.searchParams.set('categories', categoryId.toString());
        }
        else {
            url.search = '';
            url.pathname = `/${i18next.language}/assortment`;
            url.searchParams.set('categories', categoryId.toString());
        }

        return `${url.pathname}${url.search}`;
    }

    getItem(id: string): Category|null  {
        return this.items.find((item:Category) => item.id === id) ?? null;
    }

    getItemByDrupalId(drupalId: number): Category|null {
        return this.items.find((item:Category) => item.drupal_id === drupalId) ?? null;
    }

    getCurrentCategoryFilterId(): number|null {
        const url = new URL(window.location.href);

        const categoryId = url.searchParams.get('categories');

        return categoryId && categoryId.length > 0 ? parseInt(categoryId) : null;
    }

    getCurrentCategoryFilter(): Category|null {
        const id = this.getCurrentCategoryFilterId();

        return id ?  this.getItemByDrupalId(id) : null;
    }

    getCurrentCategoryFilterParent(): Category|null {
        const category = this.getCurrentCategoryFilter();
        if (category === null) {
            return null;
        }

        if (category.parent ?? null) {
            const parent = this.getParent(category);

            return parent ?? category;
        }

        return category;
    }

    getParent(item: Category): Category|null {
        return item.parent ? this.getItem(item.parent) : null;
    }

    getChildren(category: Category): Category[] {
        return this.items.filter((item: Category) => item.parent === category.id);
    }

}