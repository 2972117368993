export const convertDateToDateFormatted = (inputDate: string|null)=>  {
    if (!inputDate) {
        return '';
    }

    const date = new Date(inputDate);
    return new Intl.DateTimeFormat('nl-NL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }).format(date);
}

export const formatPriceToEuro = (price: number) => {
    return new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
    }).format(price);
}