import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios, {AxiosError} from "axios";
import {CornyBakersEnv} from "../../../../../shared/environment/features/CornyBakersEnvironment";
import PasswordSliceModel from "./resetPasswordSliceModel";
import i18next from "i18next";

axios.defaults.withCredentials = true

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

export const resetPassword = createAsyncThunk(
    "password/reset",
    async (username: string, thunkAPI) => {
        const data = {
            name: username,
        }
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;

        try {
            await axios.post(API_BASE_URL + formatLang + '/user/password?_format=json', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return username;
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    },
);

const initialState = {
    loading: false,
    error: null,
    mailed: false,
    mail: '',
} as PasswordSliceModel;

const passwordSlice = createSlice({
    name: "passwordReset",
    initialState,
    reducers: {
        resetPasswordReset: () => initialState
    },
    extraReducers(builder) {
        builder.addCase(resetPassword.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(resetPassword.fulfilled, (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = null;
            state.mailed = true;
            state.mail = action.payload
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        builder.addCase(resetPassword.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload;

            if(action.payload === "Request failed with status code 400") {
                state.error = null;
                state.mailed = true;
            }
        })
    }
});

export const { resetPasswordReset } = passwordSlice.actions;
export default passwordSlice.reducer;