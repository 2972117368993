import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../../store";
import {InvoiceModel, InvoicePaymentUpdateResponse, InvoiceSliceModel} from "../../domain/invoiceSliceModel";
import {axiosGet, axiosPost} from "../../../../shared/utility/api";

const initialState: InvoiceSliceModel = {
    loading: false,
    loaded: false,
    error: null,
    invoices: [],
    invoicesWithOrder: [],
    invoicesWithoutOrder: [],
    invoicePaymentUpdate: null,
};

export const getOpenInvoices = createAsyncThunk(
    "invoicesSlice/getOpenInvoices",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const userId = state.currentUser?.currentUser?.id ?? 0;

        try {
            const response = await axiosGet(`/api/cash-invoice/user/${userId}/relation/open-invoices`);
            return {
                invoices: response.data.data as InvoiceModel[],
            }
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    }
);

export const createCashInvoicePayment = createAsyncThunk(
    "invoicesSlice/createCashInvoicePayment",
    async (props: {cashInvoiceId: string}, thunkAPI) => {
        const {cashInvoiceId} = props;

        try {
            const response = await axiosPost(`/api/cash-invoice/${cashInvoiceId}/create-payment`);

            return response.data;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    }
)

export const refreshInvoicePaymentUpdate = createAsyncThunk(
    "invoicesSlice/refreshCashInvoicePaymentUpdate",
    async (props: { cashInvoiceId: string }, thunkAPI) => {
        const {cashInvoiceId} = props;
        try {
            const response = await axiosPost(`/api/cash-invoice/${cashInvoiceId}/payment-return`);

            return response.data as InvoicePaymentUpdateResponse;
        } catch (error: unknown) {
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    }
);

const invoicesSlice = createSlice({
    name: 'invoicesSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getOpenInvoices.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOpenInvoices.rejected, (state, action) => {
            state.loading = false;
            state.loaded = true;
            state.error = action.payload as string;
        });
        builder.addCase(getOpenInvoices.fulfilled, (state, action) => {
            state.loaded = true;
            state.loading = false;
            state.invoices = action.payload.invoices;
            state.invoicesWithOrder = action.payload.invoices.filter(invoice => invoice.orderId);
            state.invoicesWithoutOrder = action.payload.invoices.filter(invoice => !invoice.orderId);
        });
        builder.addCase(refreshInvoicePaymentUpdate.rejected, (state, action) => {
            state.error = action.payload as string;
        });
        builder.addCase(refreshInvoicePaymentUpdate.fulfilled, (state, action) => {
            state.invoicePaymentUpdate = action.payload as InvoicePaymentUpdateResponse;
        });
    }
});

export default invoicesSlice.reducer;
