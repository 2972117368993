import i18next from "i18next";
import axios from "axios";
import {CornyBakersEnv} from "../environment/features/CornyBakersEnvironment";

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

export const axiosGet = async (url: string) => {
    const lang = i18next.language;
    const formatLang = lang === 'nl' ? '' : `/${lang}`;
    const apiUrl = API_BASE_URL + `${formatLang}${url}`;

    return await axios.get(apiUrl, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

export const axiosPost = async (url: string) => {
    const lang = i18next.language;
    const formatLang = lang === 'nl' ? '' : `/${lang}`;
    const apiUrl = API_BASE_URL + `${formatLang}${url}`;

    return await axios.post(apiUrl, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};