import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { ProductMenuItemProps } from "../../../domain/models/productMenuModel";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Category } from "../../../../header/infrastructure/slices/menuSlice/menuSliceModel";

// Function to check if the current category or any child category should be expanded
function isMenuItemExpanded(currentCategoryFilterId: number|null, categoryToCheck: Category)  {
    if (!currentCategoryFilterId) {
        return false;
    }

    if (categoryToCheck.drupal_id === currentCategoryFilterId || categoryToCheck.childDrupalIds.includes(currentCategoryFilterId)) {
        return true;
    }

    // Check child categories recursively
    for (const child of categoryToCheck.children) {
        if (isMenuItemExpanded(currentCategoryFilterId, child)) {
            return true;
        }
    }

    return false;
}

export const ProductMenuItem = ({ category, service, parentKey }: ProductMenuItemProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const navigation = useNavigate();

    const uniqueKey = parentKey ? `${parentKey}-${category.id}` : category.id;

    const toggleExpand = () => setIsExpanded((prevState) => !prevState);

    const calculatePaddingGap = (key: string) => {
        return (key.split('-').length - 1) * 4; // Adjust the multiplier to control padding gap
    };

    const goto = (id: number) => {
        service.applyCategoryFilter(navigation, id);
    }

    const gapStyle = {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: `${calculatePaddingGap(uniqueKey)}px`,
        paddingRight: 0,
        fontWeight: isExpanded || isActive ? 'bold' : 'normal',
        marginTop: '1rem',
    };

    useEffect(() => {
        const currentCategoryFilterId = service.getCurrentCategoryFilterId();
        const isExpanded = isMenuItemExpanded(currentCategoryFilterId, category);
        setIsExpanded(isExpanded);
        setIsActive(currentCategoryFilterId === category.drupal_id);
    }, [category, service, window.location.href]);

    // Render leaf node (no children)
    if (category.children.length < 1) {
        return (
            <ListGroup.Item style={gapStyle}>
                <a
                    onClick={(e) => {
                        e.stopPropagation();
                        goto(category.drupal_id);
                    }}
                    className={`text-decoration-none text-white cursor-pointer`}
                >
                    {category.name}
                </a>
            </ListGroup.Item>
        );
    }

    // Render parent node (with children)
    return (
        <div key={uniqueKey}>
            <ListGroup.Item className={"d-flex"} style={gapStyle}>
                <a
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsExpanded(true);
                        goto(category.drupal_id);
                    }}
                    className={`text-decoration-none text-white cursor-pointer`}
                >
                    {category.name}
                </a>

                <div className={"ms-auto"}>
                    {isExpanded
                        ? <FaChevronUp onClick={toggleExpand} />
                        : <FaChevronDown onClick={toggleExpand} />}
                </div>
            </ListGroup.Item>
            {isExpanded && (
                <ListGroup
                    defaultActiveKey={`#${category.id}`}
                    variant="flush"
                    className="sub-categories"
                >
                    {category.children.map((childCategory) => (
                        <ProductMenuItem
                            key={`${uniqueKey}-${childCategory.id}`}
                            category={childCategory}
                            service={service}
                            parent={category}
                            parentKey={uniqueKey}
                        />
                    ))}
                </ListGroup>
            )}
        </div>
    );
};
