import {useTranslation} from "react-i18next";
import React from "react";
import {Col} from "react-bootstrap";
import {InvoiceModel} from "../../../invoice/domain/invoiceSliceModel";
import {convertDateToDateFormatted, formatPriceToEuro} from "../../../../shared/utility/converters";
import {BiChevronRight} from "react-icons/bi";
import {useInvoicePaymentHandlers} from "../../../invoice/infrastructure/hooks/useInvoicePaymentHandlers";

type Props = {
    invoice: InvoiceModel;
};

export function InvoiceWithoutOrderColView({invoice}: Props) {
    const {t} = useTranslation('common');
    const invoiceNumberText = invoice.invoiceNumber ? <>&nbsp; | &nbsp; {t('Invoice number')} {invoice.invoiceNumber}</> : null;
    const cashOrderIdText = invoice.cashOrderId ? <>&nbsp; | &nbsp; {t('Cash order number')} {invoice.cashOrderId}</> : null;
    const {createPayment} = useInvoicePaymentHandlers();

    return (
        <Col xs={12} className={'order-col'}>
            <div className={"text-end d-flex justify-content-end align-items-center"}>
                <div className={"me-auto"}>{convertDateToDateFormatted(invoice.entryDate)} {invoiceNumberText} {cashOrderIdText}</div>
                <div className={"me-4"}> {t('Outstanding (incl. VAT)')}: {formatPriceToEuro(invoice.outstandingAmount)}</div>
                <a className={'btn btn-primary btn-sm'} onClick={() => createPayment(invoice.id)}>
                    {t('Pay now')}
                    <BiChevronRight size={'20px'} color={'white'} />
                </a>
            </div>
        </Col>
    );
}

