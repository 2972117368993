import {PageTitle} from "../../../page/features/PageTitle";
import {useTranslation} from "react-i18next";
import {DefaultPage} from "../../../page/features/default";
import {Col, Row} from "react-bootstrap";
import React from "react";
import {useSearchParams} from "react-router-dom";

export function CashInvoicePaymentFailedPage() {
    const {t} = useTranslation('common');
    const [searchParams] = useSearchParams();

    return (
        <DefaultPage className={"mt-5 checkout-completed"} showBreadCrumbs={false}>
            <PageTitle title={t('Invoice payment failed')}/>
            <Row>
                <Col xs={12} className={'mb-5'}>
                    <div className={'checkout-completed-notification'}>
                        <p>
                            {t('Your payment for invoice {{invoiceNumber}} has failed.', {
                                invoiceNumber: searchParams.get('invoiceNumber')
                            })}
                        </p>
                        <p>
                            {t('Please try again or contact us if you need help.')}
                        </p>
                    </div>
                </Col>
            </Row>
        </DefaultPage>
    )
}