import {PageTitle} from "../../../page/features/PageTitle";
import {useTranslation} from "react-i18next";
import {DefaultPage} from "../../../page/features/default";
import {Col, Row} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useTypedSelector";
import React, {useEffect} from "react";
import {getOrderInfo} from "../../infrastructure/slices/checkoutCompletedSlice";
import {Loading} from "../../../general/features/loading";
import {toast} from "react-toastify";
import {Navigate} from "react-router-dom";
import OrderOverview from "./components/orderOverview";
import {CompletedOrder} from "../../infrastructure/slices/checkoutCompletedSliceModel";
import {formatOrderDate} from "../../../account/infrastructure/utility/utility";

export const OrderHeader = (props: { placed: string | null, identifier: string }) => {
    const {placed, identifier} = props;

    const {t} = useTranslation('common');

    const dateFormatted = placed ? formatOrderDate(placed) : '';

    return <p>{dateFormatted} | {t('Order number')} {identifier}</p>
}


export function CheckoutCompletedPage() {
    const {t} = useTranslation('common');

    const orderUuid = localStorage.getItem('placed');
    const dispatch = useAppDispatch();

    const {loading, loaded, order, error} = useAppSelector(state => state.checkoutCompleted);

    useEffect(() => {
        if (!loading && orderUuid) {
            dispatch(getOrderInfo({order: orderUuid}))
        }
    }, [orderUuid])

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    if (!orderUuid || orderUuid.length === 0) {
        return <Navigate to="/cart" replace/>
    }

    return (
        <DefaultPage className={"mt-5 checkout-completed"} showBreadCrumbs={false}>
            <PageTitle title={t('Order placed')}/>
            <Row>
                <Col xs={12} className={'mb-5'}>
                    <div className={'checkout-completed-notification'}>
                        <h2>
                            {t('Thank you for your order at Corny Bakers')} <br/>
                            {t('We will immediately start working on the order below.')}
                        </h2>
                        <p>
                            {t('An order confirmation has been sent to the email address known to us')}: {order?.mail ?? ''}
                        </p>
                        <p>
                            {t('Your order will be delivered with the general terms and conditions of delivery that you have accepted.')}
                            <br/>
                            <a href="https://cornybakers.nl/wp-content/uploads/Algemene-Voorwaarden-Corny-Bakers.pdf" target="_blank" rel={'noreferrer'}>
                                {t('Here')}
                            </a> {t('you can read the Corny Bakers terms and conditions again')}.
                        </p>
                    </div>
                </Col>

                <Col xs={12} className={'mt-5'}>
                    <div className={'p-rel mh-350'}>
                        {(!loaded && loading) ? <Loading/> : <>
                            <OrderHeader placed={order?.placed ?? ''} identifier={`${order?.order_number ?? ''}`}/>
                            <OrderOverview order={order as CompletedOrder} key={order?.order_items?.length ?? 0}/>
                        </>}
                    </div>
                </Col>
            </Row>
        </DefaultPage>
    )
}